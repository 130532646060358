import * as _ from 'lodash';
import {
  ApplicationApi,
  ApplicationSession,
  ApplicationSessionUser,
  InstanceConfiguration,
  MabbleModule,
  MabbleModuleContentItem,
  MabbleModuleWidget,
  MabbleModuleWidgetContainer,
  MBL_KEY_MODULES, MBL_STATUS_READY,
  MBL_TYPE_CONTAINER,
  MBL_TYPE_CONTENT,
  MBL_TYPE_INSTANCE,
  MBL_TYPE_MENU_ITEM,
  MBL_WIDGET_STYLE_A,
  MBL_WIDGET_STYLE_NAV_ITEM,
  MBL_WIDGET_TYPE_LINK,
  MBL_WIDGET_TYPE_WIDGET,
  uuidv4
} from '@core/models/application-session.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';

/**
 * mzs: 2017-11-14.120612
 * mzs: 2017-12-27.153858
 * mzs: 2018-01-05.070827
 * ...
 * */
export class SessionEvent {
  _onion?: number;
  activeRecord?: any;
  event: string;
  key: string;
  source: any;
  module?: MabbleModule<any>;

  constructor(options?: any) {
    this._onion = (this._onion || options && options._onion || 0) + 1;
    this.activeRecord = options && options.activeRecord;
    this.event = options && options.event;
    this.key = options && options.key;
    this.source = options && options.source;
    this.module = options && options.module;

    if (this.source && this.source._onion) {
      this._onion = this._onion + this.source._onion;
    }

  }


}

export class WatcherStore {
  private _map: { [p: string]: BehaviorSubject<any> };

  constructor(root: object) {
    this._map = {
      root: new BehaviorSubject(root)
    };
  }

  next(key: string, value?: any): BehaviorSubject<any> {
    const reply = this.get(key);
    if (value) {
      reply.next(value);
    }
    return reply;
  }

  private get(key: string): BehaviorSubject<any> {
    const _m = this._map[key];
    if (_m) {
      return _m;
    }
    this._map[key] = new BehaviorSubject({});
    return this._map[key];
  }
}

@Injectable()
export class ApplicationSessionService {

  private readonly _watchers: WatcherStore = new WatcherStore(new ApplicationSession({}));

  private anonymous: ApplicationSessionUser;
  private admin_roles: string[] = ['ROLE_ADMINISTRATOR', 'ROLE_SYS_ADMIN'];
  private application: ApplicationSession;


  constructor() {
    this.fetch('root-application-session.seed.json', (rootdata) => {
      this.fetch(environment.seed, (instancedata) => {

        const seed = _.merge({}, rootdata, instancedata, {
          enter_any_emergency_override_here: false,
          timestamp_of_service_construction: new Date().getTime()
        });

        this.application = new ApplicationSession(seed);

        // init session wide roles
        this.admin_roles = ['ROLE_SYS_ADMIN'];
        if (this.application.defaults.admin_roles) {
          this.application.defaults.admin_roles.forEach(ar => this.admin_roles.push(ar));
        }


        this.application.instance.state.next(this.seedInstanceConfiguration());


        // publish the session
        this._watchers.next('root', this.application);
        this.fire();
      });
    });
  }

  get adminRoles(): string[] {
    return [...this.admin_roles];
  }


  public fire() {
    this._watchers.next('menu', this.application.menu);
    this._watchers.next('session.api', this.application.api);
    this._watchers.next('user', this.application.user);
    this._watchers.next('user.data', this.application.user.data);

    this._watchers.next('root', this.application);
  }


  public set(key: string, value: any): void {
    switch (key) {
      case 'session.auth.token': {
        this.application.user.auth_token = value;
        this._watchers.next('user', this.application.user);
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      case 'session.user': {
        this.application.user = value;
        this._watchers.next('user', this.application.user);
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      case('session.mabble.host.auth.token'): {
        this.application.mabbleUser.auth_token = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        break;
      }
      case('session.mabble.host.user'): {
        this.application.mabbleUser = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        break;
      }
      case ('user'):
      case ('user.data'):
      case ('session.user.data'): {
        this.application.user.data = value;
        this._watchers.next('user.data', this.application.user.data);
        break;
      }
      default: {
        this._watchers.next(key).getValue();
      }
    }
    this._watchers.next('root', this.application);
  }


  public get(key: string): any {
    switch (key) {
      case (''): {
        break;
      }
      case ('api'): {
        return this.application.api;
      }
      case ('apiHost'):
      case ('api.host'): {
        return this.application.api.host;
      }
      case ('user.data'): {
        return this.application.user.data;
      }
      case ('default.home.route'): {
        return this.application.defaults.home.route;
      }
      case ('session'): {
        return this.application;
      }
      case ('session.user'): {
        return this.application.user;
      }
      default: {
      }
    }
  }


  public observe(key: string): Observable<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {

    if (!this._watchers || !this.application) {
      return undefined;
    }

    switch (key) {
      case 'session' : {
        return this._watchers.next('root').asObservable();
      }
      case ('session.m_api') : {
        return this._watchers.next(key, this.application.m_api).asObservable();
      }
      case ('apiHost') :
      case ('api.host') : {
        return of(this.application.api.host);
      }
      case 'session.menu' : {
        return this._watchers.next('menu', this.application.menu).asObservable();
      }
      case 'session.user' : {
        return this._watchers.next('user', this.application.user).asObservable();
      }
      case 'session.user.data' : {
        if (this.application.user && this.application.user.data
          && this.application.user.auth_token && this.application.user.auth_token.length > 0) {
          return this._watchers.next('user.data', this.application.user.data).asObservable();
        } else {
          return this._watchers.next('user.data', this.anonymous.data).asObservable();
        }
      }
      default: {
        return this._watchers.next(key).asObservable();
      }
    }

  }


  public subscribe(key: string): BehaviorSubject<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {
    switch (key) {
      case ('menu') : {
        return this._watchers.next('menu', this.application.menu);
      }
      case ('is-authenticated') :
      case ('user') :
      case ('users') :
      case ('session.user') : {
        return this._watchers.next('user', this.application.user);
      }
      case ('user.data') :
      case ('session.user.data') : {
        if (this.application.user && this.application.user.data
          && this.application.user.auth_token && this.application.user.auth_token.length > 0) {
          return this._watchers.next('user.data', this.application.user.data);
        } else {
          return this._watchers.next('user.data', this.anonymous.data);
        }
      }
      case ('session') :
      default: {
        return this._watchers.next('root', this.application);
      }
    }
  }


  private fetch(datkey: string, cb: any) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/seed/' + datkey);
    req.onload = () => cb(JSON.parse(req.response));
    req.send();
  }


  private seedInstanceConfiguration(): InstanceConfiguration {
    const MBL_MODULE_KEY_MCIL_UUID = uuidv4();

    // top nav
    const home_link = new MabbleModuleWidget({
      name: 'Home Menu Item',
      description: 'A regular link (i.e href) to the home page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/app/authentication/signin',
        widget_title: 'Home',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const configuration_link = new MabbleModuleWidget({
      name: 'Configuration Menu Item',
      description: 'A regular link (i.e href) to the configuration component page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_route: '/app/sec/config/home',
        widget_title: 'Configuration',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const logout_link = new MabbleModuleWidget({
      name: 'Log Out Menu Item',
      description: 'A regular link (i.e href) performing the user logout page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_USER']
      },
      properties: {
        widget_route: '/app/authentication/signout',
        widget_title: 'Log Out',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const login_link = new MabbleModuleWidget({
      name: 'Log In Menu Item',
      description: 'A regular link (i.e href) performing the user login page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_USER']
      },
      properties: {
        widget_route: '/app/authentication/signin',
        widget_title: 'Log In',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const reports_link = new MabbleModuleWidget({
      name: 'Committee Reports Link',
      description: 'A regular link (i.e href) to the committee reports page',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/app/sec/grant-management/committee-recommendation',
        widget_title: 'GM Reports',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });
    const dashboard_link = new MabbleModuleWidget({
      name: 'Grant Management Dashboard Link',
      description: 'A regular link (i.e href) to the Grant Management Dashboard',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_MENU_ITEM,
      properties: {
        widget_route: '/app/sec/grant-management/home',
        widget_title: 'Dashboard',
        widget_style: MBL_WIDGET_STYLE_NAV_ITEM,
        widget_type: MBL_WIDGET_TYPE_LINK
      }
    });

    const bcap_fuel_provincial = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-anchor',
        widget_color: 'pink',
        widget_title: 'Fuel Provincial Data Load',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/bcap/fuel',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Bulk data loader for FUEL (province)',
        widget_width: '4'
      }
    });
    const fuel = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-anchor',
        widget_color: 'yellow',
        widget_title: 'Fuel',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/fuel/landing',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'FUEL',
        widget_width: '4'
      }
    });

    const dev_spike_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'fa fa-bus',
        widget_color: 'yellow',
        widget_title: 'Spike: Drag and Drop',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/spike',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Test and validate functional elements',
        widget_width: '4'
      }
    });
    const dev_spike_widget_form_capture = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'orange',
        widget_title: 'Functional Spike',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/form/capture/list/d0a46df7-1135-41b9-adcb-02898b82a69d',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Test and validate functional elements',
        widget_width: '4'
      }
    });
    const dev_spike_widget_module_builder = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'fa fa-arrow-right',
        widget_color: 'red',
        widget_title: 'Functional Spike',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/modules',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Module Definition UI.',
        widget_width: '4'
      }
    });
    const dev_spike_widget_user_registration = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_ANONYMOUS']
      },
      properties: {
        widget_classes: 'fa fa-user',
        widget_color: 'black',
        widget_title: 'User Registration',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/pub/registration/start',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'User and Organisation registration and on-boarding.',
        widget_width: '4'
      }
    });


    const gm_admin_console_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'orange',
        widget_title: 'GM Admin Console',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/admin-console',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management Tools',
        widget_width: '7'
      }
    });
    const gm_application_widget = new MabbleModuleWidget({
      name: 'Grant Management: Applications',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_APPLICANT']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'brown',
        widget_title: 'Grant Application',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/app.workflow.grant.application.csu',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Application collections',
        widget_width: '4'
      }
    });
    const gm_coordinators_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER']
      },
      properties: {
        widget_classes: 'icon-basic-book-pencil',
        widget_color: 'yellow',
        widget_title: 'Coordinator Management',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/coordinators',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'coordinator role management',
        widget_width: '4'
      }
    });
    const gm_review_dashboard_widget = new MabbleModuleWidget({
      name: 'Grant Management',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'red',
        widget_title: 'Grant Management: Dashboard',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-management/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Application Dashboard',
        widget_width: '4'
      }
    });


    const kbms_doclib = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'orange',
        widget_title: 'Documents',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/form/capture/list/d0a46df7-1135-41b9-adcb-02898b82a69d',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'KBMS Document Library',
        widget_width: '4'
      }
    });
    const kbms_search = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'fa fa-search',
        widget_color: 'light-blue',
        widget_title: 'Search',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/kb-search/search',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Knowledgebase Search',
        widget_width: '4'
      }
    });


    const mbl_codes_file_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'purple',
        widget_title: 'Codes File',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/lookup/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Lookup and Code meta-data',
        widget_width: '6'
      }
    });
    const mbl_content_manager = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER']
      },
      properties: {
        widget_classes: 'icon-basic-book-pencil',
        widget_color: 'light-blue',
        widget_title: 'Content Manager',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/content/manager/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'content management system',
        widget_width: '4'
      }
    });
    const mbl_data_loader_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-arrows-drag-right-dashed',
        widget_color: 'orange',
        widget_title: 'Data Loader',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/data-loader/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'data loader',
        widget_width: '4'
      }
    });
    const mbl_forms_dashboard_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-lightbulb',
        widget_color: 'green',
        widget_title: 'Form Builder Dashboard',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/form/dashboard',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'form builder',
        widget_width: '4'
      }
    });


    const sap_data_migration_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN']
      },
      properties: {
        widget_classes: 'icon-arrows-circle-minus',
        widget_color: 'yellow',
        widget_title: 'SAP Data Migration Runner',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/dm-sap-runner',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'data migrate - form capture 2017-11-02',
        widget_width: '4'
      }
    });


    const sat_assessment_builder_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-star',
        widget_color: 'grey',
        widget_title: 'Assessment Builder',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/assessments/definition/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'assessment definition manager',
        widget_width: '4'
      }
    });
    const sat_assessment_campaign_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-clessidre',
        widget_color: 'pink',
        widget_title: 'Assessment Campaigns',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/assessments/campaign/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'assessment campaign dashboard',
        widget_width: '4'
      }
    });
    const sat_assessment_capture_widget = new MabbleModuleWidget({
      name: 'Self Assessment Workflow',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_PARTICIPANT']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'brown',
        widget_title: 'Self Assessment',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/app.workflow.tiger',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Self Assessment Questionnaire',
        widget_width: '4'
      }
    });
    const sat_participant_landing_widget = new MabbleModuleWidget({
      name: 'Participant Candidate Landing',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_USER', 'ROLE_PARTICIPANT', 'ROLE_APPLICANT']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'green',
        widget_title: 'Your Dashboard',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/participant/sat/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Participant Dashboard',
        widget_width: '3'
      }
    });


    const tgr_wflow_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_USER', 'ROLE_GRANT_APPLICANT']
      },
      properties: {
        widget_classes: 'icon-basic-keyboard',
        widget_color: 'orange',
        widget_title: 'TB SED Grant Application',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/app.workflow.tiger',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'TBSED Apr/May 2018 Assessment Application Window',
        widget_width: '4'
      }
    });

    const usc_grant_management_reports_widget = new MabbleModuleWidget({
      name: 'Reports',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'blue',
        widget_title: 'Grant Management: Reports',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-management/committee-recommendation',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Management: Reports',
        widget_width: '4'
      }
    });
    const gm_grant_window_widget = new MabbleModuleWidget({
      name: 'Grant Window',
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR', 'ROLE_GRANT_MANAGER', 'ROLE_GRANT_COORDINATOR']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'green',
        widget_title: 'Grant Window',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/grant-window/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Grant Window',
        widget_width: '4'
      }
    });

    const user_admin_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'fa fa-address-card-o',
        widget_color: 'blue',
        widget_title: 'Users',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/users/list',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'User Administration',
        widget_width: '4'
      }
    });

    const financial_reporting_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-book-pencil',
        widget_color: 'purple',
        widget_title: 'Financial Reporting',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/financial-reporting/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Financial Reporting',
        widget_width: '4'
      }
    });
    const beneficiary_reporting_partner_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-headset',
        widget_color: 'orange',
        widget_title: 'Partner Beneficiary Reporting',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/beneficiary-reporting/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Beneficiary Reporting',
        widget_width: '4'
      }
    });
    const reporting_landing_widget = new MabbleModuleWidget({
      status: MBL_STATUS_READY,
      type: MBL_WIDGET_TYPE_WIDGET,
      accessControl: {
        roles: ['ROLE_SYS_ADMIN', 'ROLE_ADMINISTRATOR']
      },
      properties: {
        widget_classes: 'icon-basic-webpage-img-txt',
        widget_color: 'red',
        widget_title: 'Partner Landing Page',
        widget_style: MBL_WIDGET_STYLE_A,
        widget_route: '/app/sec/partner-landing/home',
        widget_type: MBL_WIDGET_TYPE_LINK,
        widget_text: 'Partner Landing',
        widget_width: '4'
      }
    });


    // modules:
    const sec_instance_top_nav = {
      dev: [
        home_link,
        configuration_link,
        logout_link
      ],
      // fuel: [
      //   home_link,
      //   logout_link
      // ],
      sap: [
        home_link,
        logout_link
      ],
      satrust: [
        home_link,
        logout_link
      ],
      sus: [
        home_link,
        configuration_link,
        logout_link
      ],
      tgr: [
        home_link,
        logout_link
      ],
      usc: [
        reports_link,
        dashboard_link,
      ]
    };
    const secure_admin_dashboard_widgets = {
      dev: [
        dev_spike_widget,
        dev_spike_widget_module_builder,
        dev_spike_widget_user_registration,
        gm_admin_console_widget,
        gm_application_widget,
        gm_coordinators_widget,
        gm_grant_window_widget,
        gm_review_dashboard_widget,
        kbms_doclib,
        kbms_search,
        mbl_codes_file_widget,
        mbl_content_manager,
        mbl_data_loader_widget,
        mbl_forms_dashboard_widget,
        sat_assessment_builder_widget,
        sat_assessment_campaign_widget,
        sat_assessment_capture_widget,
        usc_grant_management_reports_widget,
        user_admin_widget,
        reporting_landing_widget
      ],
      fuel: [
        bcap_fuel_provincial,
        fuel
      ],
      sap: [
        mbl_codes_file_widget,
        mbl_data_loader_widget,
        mbl_forms_dashboard_widget,
        sap_data_migration_widget
      ],
      satrust: [
        kbms_doclib,
        kbms_search,
        mbl_codes_file_widget,
        mbl_forms_dashboard_widget
      ],
      sus: [
        mbl_codes_file_widget,
        mbl_content_manager,
        mbl_forms_dashboard_widget,
        sat_assessment_builder_widget,
        sat_assessment_campaign_widget,
      ],
      tgr: [
        gm_admin_console_widget,
        gm_review_dashboard_widget,
        gm_grant_window_widget,
        mbl_codes_file_widget,
        mbl_content_manager,
        mbl_forms_dashboard_widget,
        sat_assessment_builder_widget,
        sat_assessment_capture_widget,
      ],
      usc: [
        mbl_codes_file_widget,
        mbl_content_manager,
        usc_grant_management_reports_widget,
        financial_reporting_widget,
        beneficiary_reporting_partner_widget,
        reporting_landing_widget
      ]
    };
    const secure_user_dashboard_widgets = {
      dev: [],
      fuel: [],
      sap: [
        mbl_codes_file_widget,
        mbl_data_loader_widget,
        mbl_forms_dashboard_widget,
        sap_data_migration_widget
      ],
      satrust: [
        kbms_doclib,
        kbms_search,
      ],
      sus: [
        sat_participant_landing_widget
      ],
      tgr: [
        tgr_wflow_widget
      ],
      usc: [
        gm_review_dashboard_widget,
        gm_grant_window_widget,
        usc_grant_management_reports_widget
      ]
    };


    // main content blocks:
    const public_landing_page_content = new MabbleModuleContentItem({
      name: 'Public Landing Page Content',
      description: 'The general public facing content used on the welcome page.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTENT,
      context: this.application.content.home
    });

    const secure_admin_landing_widget_container = new MabbleModuleWidgetContainer({
      name: 'Admin Landing Page Content',
      description: 'The content used on the administration dashboard.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTAINER,
      contains: secure_admin_dashboard_widgets[this.application.ssitk]
    });
    const secure_user__landing_widget_container = new MabbleModuleWidgetContainer({
      name: 'User Landing Page Content',
      description: 'The content used on the user home.',
      status: MBL_STATUS_READY,
      type: MBL_TYPE_CONTAINER,
      contains: secure_user_dashboard_widgets[this.application.ssitk]
    });


    const ic_pub = new InstanceConfiguration({
      properties: {
        instance_brand_banner_img: this.application.defaults.home.banner,
        instance_brand_banner_visible: true,
        instance_brand_logo_img: this.application.defaults.home.logo,
        instance_left_sidebar_visible: false,
        instance_main: public_landing_page_content,
        instance_top_nav: [home_link, login_link],
      }
    });
    const ic_user = new InstanceConfiguration({
      properties: {
        instance_brand_banner_visible: !this.application.user || !this.application.user.isAuthenticated(),
        instance_left_sidebar_visible: false,
        instance_main: secure_user__landing_widget_container,
        instance_top_nav: sec_instance_top_nav[this.application.ssitk] ? sec_instance_top_nav[this.application.ssitk] : [],
      }
    });
    const ic_admin = new InstanceConfiguration({
      properties: {
        instance_brand_banner_visible: !(this.application.user && this.application.user.isAuthenticated()),
        instance_left_sidebar_visible: false,
        instance_main: secure_admin_landing_widget_container,
        instance_top_nav: sec_instance_top_nav[this.application.ssitk] ? sec_instance_top_nav[this.application.ssitk] : [],
      }
    });

    return new InstanceConfiguration({
      _mblId: MBL_MODULE_KEY_MCIL_UUID,
      type: MBL_TYPE_INSTANCE,
      context: MBL_KEY_MODULES,
      identifier: 1,
      name: 'Instance Configuration',
      description: 'Application module configuration for landing page bootstrap.',
      status: MBL_STATUS_READY,
      properties: ic_pub.properties,
      initialStates: [
        {key: 'ic_admin', state: ic_admin},
        {key: 'ic_pub', state: ic_pub},
        {key: 'ic_user', state: ic_user}
      ]
    });

  }


}
