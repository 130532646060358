import {Injectable} from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {ObjectEntityService} from '../object-entity.service';
import {ObjectEntity} from '@core/models/application-session.model';

@Injectable()
export class ObjectEntityAllResolverService implements Resolve<ObjectEntity<any>[]> {

  constructor(private svc: ObjectEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ObjectEntity<any>[]> {
    return this.svc.getAll().then(entities => {
      return entities || [];
    });
  }
}
