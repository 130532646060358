import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

export interface AlertModel {
  title: string;
  message: string;
}


@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent extends DialogComponent<AlertModel, null> implements OnInit {

  title: string;
  message: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
    // no op
  }

}
