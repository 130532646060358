import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationSessionService} from '@services/application-session.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {AbstractMabbleUiAwareComponent} from '@shared/abstract-user-aware-ui.component';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-thing',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private title: Title,
              public sessionService: ApplicationSessionService,
              public translate: TranslateService,
              public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              protected http: HttpClient) {

    const browserLang: string = translate.getBrowserLang();
    translate.addLangs(['en', 'afr']);
    translate.setDefaultLang('en');
    translate.use(browserLang.match(/en|afr/) ? browserLang : 'en');

  }

  ngOnInit(): void {
    try {
      this.title.setTitle('please be patient...');
      this.sessionService.observe('session').subscribe(session => {
        // console.log('%c fuel-datamine.app.ngOnInit: ->', 'color:red;background-color:green;', session);
        this.title.setTitle('..please be patient.');
      });
    } catch (e) {
      this.title.setTitle('loaded...');
    }
  }

}
