import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {FieldType, FormlyFormBuilder} from '@ngx-formly/core';
import * as clonedeep from 'lodash.clonedeep';

// <quill-editor
//   [(ngModel)]="title"
//   [maxLength]="5"
//   [minLength]="3"
//   [required]="true"
//   [readOnly]="isReadOnly"
//   [modules]="{toolbar: false}"
//   (onContentChanged)="logChange($event);"
//   (onSelectionChanged)="logSelection($event);"
// ></quill-editor>

@Component({
  selector: 'app-formly-quill',
  template: `
    <!--<quill-editor-->
    <!--[(ngModel)]="field.key"-->
    <!--[modules]="{toolbar: false}"-->
    <!--[readOnly]="field.templateOptions.disabled"-->
    <!--(onContentChanged)="logChange($event);"-->
    <!--(onSelectionChanged)="logSelection($event);"-->
    <!--&gt;</quill-editor>-->
  `,
})
export class QuillTypeComponent extends FieldType implements OnInit {

  constructor(private builder: FormlyFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  logChange($event: any) {
  }

  logSelection($event: any) {
  }

}
