import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ParamMap} from '@angular/router';

import {Observable} from 'rxjs';

@Injectable()
export class OpenLandingRoutesGuard implements CanActivate {

  IS_PASSWORD_RESET: boolean;

  constructor() {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const APP_GOTO = 'a';
    const USER_TOKEN_ACTION = 'id';
    const USER_TOKEN_PAYLOAD = 'payload';
    const queryParamMap: ParamMap = next.queryParamMap;
    const userId = queryParamMap.get(USER_TOKEN_ACTION);
    const token = queryParamMap.get(USER_TOKEN_PAYLOAD);
    const gotoURL = '/users/password-reset?id=' + userId + '&payload=' + token;
    return true;

  }
}
