import {ApplicationApi, ApplicationSessionUser, User} from '@core/models/application-session.model';
import {ApplicationSessionService} from '@services/application-session.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {takeWhile} from 'rxjs/operators';

@Injectable()
export class AuthenticatedUserResolver implements Resolve<User>, OnDestroy {

  protected alive: boolean;

  protected api$: Observable<ApplicationApi>;
  protected api: ApplicationApi;
  protected user$: Observable<ApplicationSessionUser>;
  protected user: ApplicationSessionUser;

  constructor(private sessionService: ApplicationSessionService) {
    this.alive = true;

    // --->
    this.api$ = this.sessionService.observe('session.api');
    this.api$
      .pipe(takeWhile(() => this.alive))
      .subscribe(api => this.api = api);

    // --->
    this.user$ = this.sessionService.observe('session.user');
    this.user$
      .pipe(takeWhile(() => this.alive))
      .subscribe(user => this.user = user);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
    const u: User = (this.user && this.user.data) || null;
    return u ? Promise.resolve(u) : Promise.reject(u);
  }
}
