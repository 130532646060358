import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

//
import {ApplicationSessionService} from './application-session.service';
import {ApplicationSession, ApplicationSessionUIConfiguration} from '@core/models/application-session.model';

@Injectable()
export class UserInterfaceConfigurationService {
  private _applicationSession: Observable<ApplicationSession>;
  private _userInterfaceConfiguration: ApplicationSessionUIConfiguration;

  constructor(private sessionService: ApplicationSessionService) {
    this._applicationSession = this.sessionService.observe('session');
    this._applicationSession.subscribe(((sess: ApplicationSession) => {
      if (sess) {
        this._userInterfaceConfiguration = sess.config;
      }
    }));

    this._userInterfaceConfiguration = new ApplicationSessionUIConfiguration();
    this._userInterfaceConfiguration.debugEnabled = false;

  }

  get userInterfaceConfiguration(): ApplicationSessionUIConfiguration {
    return this._userInterfaceConfiguration;
  }

  set userInterfaceConfiguration(value: ApplicationSessionUIConfiguration) {
    this._userInterfaceConfiguration = value;
  }


}
