export class ApiResponse {
  data?: string [];
  ids?: number [];
  message?: string;
  messages?: string[];
  statusCode?: number;
  source: any;

  constructor(options?: any) {
    if (options && Object.keys(options).length > 0) {
      this.data = options.data;
      this.ids = options.ids;
      this.message = options.message;
      this.messages = options.messages;
      this.statusCode = options.statusCode;
      this.source = options.source;
    }
  }
}
