import {Injectable} from '@angular/core';
import {ServiceSupportService} from '../service-support.service';
import {AuthService} from '../auth.service';
import {ApplicationSessionService} from '../application-session.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class OeReportingService extends ServiceSupportService {

  protected url_local: string;

  constructor(protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_local = this.url_ + this.application.api.endpoints.reports.embed.root;
  }

  getEmbedURL(): Promise<any> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._get<any>(url, headers).toPromise(); // Make it so #1.
  }

}
