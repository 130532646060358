import { Component, OnInit } from '@angular/core';
import {DialogComponent, DialogService} from 'ng2-bootstrap-modal';

export interface ConfirmModel {
  title: string;
  message: string;
}

export interface ConfirmReturnModel {
  isConfirmed: boolean;
  message: string;
}

@Component({
  selector: 'app-modal-confirm-input',
  templateUrl: './modal-confirm-input.component.html',
  styleUrls: ['./modal-confirm-input.component.css']
})
export class ModalConfirmInputComponent extends DialogComponent<ConfirmModel, ConfirmReturnModel> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  returnMessage: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm() {
    // on click of confirm button we set dialog result as true,
    // then we can get dialog result from caller code
    this.result = {
      isConfirmed: true,
      message: this.returnMessage
    };
    this.close();
  }

  cancel() {
    this.result = {
      isConfirmed: false,
      message: this.returnMessage
    };
    this.close();
  }

  ngOnInit() {
  }

}
