import {ApplicationSessionService} from './application-session.service';
import {ApiResponse} from '@core/models/api-response.model';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Role} from '@core/models/role.model';
import {ServiceSupportService} from './service-support.service';


@Injectable()
export class RoleService extends ServiceSupportService {

  private endpoint = '/roles';  // URL to the backend web api

  protected url_local: string;

  constructor(protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient) {
    super(sessionService, http);
    this.url_local = this.url_ + this.endpoint;
  }


  create(entity: Role): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._post<ApiResponse>(url, headers, entity).toPromise();
  }

  getAll(): Promise<Role[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._get<Role[]>(url, headers).toPromise();
  }

}
