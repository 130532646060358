import {MabbleModuleContentItem, MBL_TYPE_CONTENT} from '@core/models/application-session.model';

export const sortByCode = (a, b) => (a.code || 0) - (b.code || 0);
export const sortById = (a, b) => (a.id || 0) - (b.id || 0);
export const sortByLabel = (a, b) => String(a.label).trim().localeCompare(String(b.label).trim());
export const sortByLevel = (a, b) => (a.level || 0) - (b.level || 0);
export const sortByName = (a, b) => String(a.name).trim().localeCompare(String(b.name).trim());
export const sortByOrder = (a, b) => (a.order || 0) - (b.order || 0);
export const sortBySequence = (a, b) => (a.sequence || 0) - (b.sequence || 0);
export const sortByPeriod = (a, b) => (a.period || 0) - (b.period || 0);

export const codeSort = sortByCode;
export const idSort = sortById;
export const labelSort = sortByLabel;
export const levelSort = sortByLevel;
export const nameSort = sortByName;
export const orderSort = sortByOrder;
export const sequenceSort = sortBySequence;

// noinspection TsLint
export const validateNumber = n => !isNaN(parseFloat(n)) && isFinite(n) && Number(n) === n;
export const initialize2DArray = (w, h, val = null) => Array.from({length: h}).map(() => Array.from({length: w}).fill(val));
export const isArrayLike = val => {
  try {
    return !![...val];
  } catch (e) {
    return false;
  }
};

export const toKebabCase = str => str && str
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map(x => x.toLowerCase())
  .join('-');
export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');

export const sumAll = (grid: any[][]) => {
  let answer = 0;
  if (isArrayLike(grid) && isArrayLike(grid[0])) {
    grid.forEach(gx => gx.forEach(gy => {
      answer += validateNumber(gy) ? gy : 0;
    }));
  }
  return answer;
};
export const sumByCol = (grid: number[][], colIx: number): number => {
  let answer = 0;
  if (isArrayLike(grid)) {
    (grid || []).forEach(col => {
      answer += Number(col[colIx]);
    });
  }
  return answer;
};
export const sumByRow = (grid: number[][], rowIx: number): number => {
  let answer = 0;
  if (isArrayLike(grid)) {
    (grid[rowIx] || []).forEach(col => {
      answer += Number(col);
    });
  }
  return answer;
};
export const sumNumbers = (numbers: number[]): number => {
  let answer = 0;
  if (isArrayLike(numbers)) {
    (numbers || []).forEach(n => {
      answer += Number(n);
    });
  }
  return answer;
};
export function sumBy(arr, fn) {
  arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => acc + val, 0);
}



export const collectionFirst = (data) => {
  return (data && data.length === 1 ? data[0] : null);
};

export function chunkify(a, n, balanced) {
  if (n < 2) {
    return [a];
  }

  const len = a.length, out = [];
  let i = 0;
  let size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(a.slice(i, i += size));
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--);
      out.push(a.slice(i, i += size));
    }
  } else {

    n--;
    size = Math.floor(len / n);
    if (len % size === 0) {
      size--;
    }
    while (i < size * n) {
      out.push(a.slice(i, i += size));
    }
    out.push(a.slice(size * n));

  }

  return out;
}

export function groupBy(arr, fn): {} {
  const result = {};
  arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i]);
    return acc;
  }, result);
  return result;
}

// --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- >
// mzs:...
//
// --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- >
export const makeMabbleTypeContentTemplateItem = (context: string, identifier = '1'): MabbleModuleContentItem => {
  return new MabbleModuleContentItem({
    type: MBL_TYPE_CONTENT,
    context: context,
    identifier: identifier
  });
};
// --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- . --- >
