import * as _ from 'lodash';
import {ApiResponse} from '@core/models/api-response.model';
import {
  ApplicationApi,
  ApplicationSession, ApplicationSessionUser, eom,
  MBL_TYPE_OE_ERROR, ObjectEntity
} from '@core/models/application-session.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {ApplicationSessionService} from './application-session.service';
import {takeWhile} from 'rxjs/operators';
import {catchError, retry} from 'rxjs/operators';

export abstract class ServiceSupportService implements OnDestroy {

  public static HTTP_HEADERS = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');


  protected subscriptions: Subscription[] = [];

  protected api: ApplicationApi;
  protected application: ApplicationSession;
  protected user: ApplicationSessionUser;
  protected mabbleApi: ApplicationApi;

  protected mabbleUser: ApplicationSessionUser;

  protected RETRY_COUNT = 1;
  protected alive: boolean;
  protected url_: string;
  protected mabbleUrl_: string;

  public static doReject(response: ApiResponse) {
    console.error('*** service-support.service.doReject:', response);
    console.dir(response);
    return Promise.reject(response);
  }

  protected constructor(protected sessionService: ApplicationSessionService,
                        protected http: HttpClient) {
    this.alive = true;

    // ---> mabble-host api
    this.subscriptions.push(
      this.sessionService.observe('session.m_api')
        .pipe(takeWhile(() => this.alive))
        .subscribe(mabbleApi => {
          this.mabbleApi = mabbleApi;
          if (this.mabbleApi) {
            this.mabbleUrl_ = this.mabbleApi.host;
          }
        }));
    // ---> oe-host api
    this.subscriptions.push(
      this.sessionService.observe('session.api')
        .pipe(takeWhile(() => this.alive))
        .subscribe(api => {
          this.api = api;
          if (this.api) {
            this.url_ = this.api.host + this.api.base; // + this.api.endpoints...;
          }
        }));
    // ---> ..etv...
    this.subscriptions.push(
      this.sessionService.observe('session')
        .pipe(takeWhile(() => this.alive))
        .subscribe(application => {
          this.application = application;
          if (this.application && this.application.api) {
            this.url_ = this.application.api.host + this.application.api.base; // + this.api.endpoints...;
          }
        }));
    this.subscriptions.push(
      this.sessionService.observe('session.mabble.host.user')
        .pipe(takeWhile(() => this.alive))
        .subscribe(user => this.mabbleUser = user));
    this.subscriptions.push(
      this.sessionService.observe('session.user')
        .pipe(takeWhile(() => this.alive))
        .subscribe(user => this.user = user));
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(subs => {
        if (subs && !subs.closed) {
          try {
            subs.unsubscribe();
          } catch (ignore) {
          }
        }
      });
    }
  }

  public auditObjectEntity<T>(oe: ObjectEntity<T>): ObjectEntity<T> {
    if (oe) {
      oe.createdOn = oe.createdOn || new Date().getTime();
      try {
        oe.createdBy = oe.createdBy || this.application.user.data.username;
      } catch (e) {
        oe.createdBy = oe.createdBy || 'system';
      }
      try {
        oe.modifiedBy = this.application.user.data.username;
      } catch (e) {
        oe.modifiedBy = 'system';
      }
      oe.modifiedOn = new Date().getTime();
    }
    return oe;
  }

  // --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + --- + ---

  protected _get<T>(url: string, headers: HttpHeaders): Observable<T> {
    return this.http.get<T>(url, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError(ServiceSupportService.doReject)
      );
  }

  protected _post<T>(url: string, headers: HttpHeaders, payload?: any): Observable<T> {
    return this.http.post<T>(url, payload, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError((err: any, caught: Observable<T>) => {
          this.handleSaveError(payload, headers);
          return Promise.reject(err);
        })
      );
  }

  protected _put<T>(url: string, headers: HttpHeaders, payload?: any): Observable<T> {
    return this.http.put<T>(url, payload, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError((err: any, caught: Observable<T>) => {
          this.handleSaveError(payload, headers);
          return Promise.reject(err);
        })
      );
  }

  protected _remove(url: string, headers: HttpHeaders): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(url, {headers})
      .pipe(
        retry(this.RETRY_COUNT),
        catchError(ServiceSupportService.doReject)
      );
  }

  private handleSaveError(payload: any, headers: HttpHeaders) {
    const data = new ObjectEntity({
      type: MBL_TYPE_OE_ERROR,
      context: this.user.data.username,
      identifier: payload.id + ' ' + payload._mblVersion
    });
    data.properties = data.properties || {};
    data.properties = _.merge({}, data.properties, {
      original: payload,
      application: this.application,
      user: this.user,
      id: null,
      _mblVersion: 0
    });
    this._post(this.url_ + '/oe-store', headers, eom(data)).toPromise();
  }
}
