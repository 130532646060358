import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized
} from '@angular/router';

// noinspection TsLint
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';




enum RouteInterceptorEvents {
  NAVIGATION_START,
  NAVIGATION_END,
  NAVIGATION_CANCEL,
  NAVIGATION_ERROR,
  CONFIG_LOAD_START,
  CONFIG_LOAD_END,
  ROUTE_RECOGNIZED
}

@Injectable()
export class RouteInterceptorService {
  events: Map<number, Observable<Event>> = new Map();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.populateEventMap();
  }

  getRouteOnNavigationStart(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.NAVIGATION_START, iteratee);
  }

  getRouteOnNavigationEnd(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.NAVIGATION_END, iteratee);
  }

  getRouteOnNavigationCancel(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.NAVIGATION_CANCEL, iteratee);
  }

  getRouteOnNavigationError(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.NAVIGATION_ERROR, iteratee);
  }

  getRouteOnConfigLoadStart(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.CONFIG_LOAD_START, iteratee);
  }

  getRouteOnConfigLoadEnd(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.CONFIG_LOAD_END, iteratee);
  }

  getRouteOnRouteRecognized(iteratee: Function) {
    this.getRouteOn(RouteInterceptorEvents.ROUTE_RECOGNIZED, iteratee);
  }

  private getRouteOn(eventType: number, iteratee: Function) {
    this.events[eventType]
    // By returning a new Object (this.activatedRoute) into the stream, we
    // essentially swap what we're observing.
    // At this point we only run .map() if the filtered event (this.events[eventType])
    // successfully returns the event, meaning the event of 'eventType' has been triggered.
      .map(() => this.activatedRoute)
      // Traverse the state tree to find the last activated route, and then
      // return it to the stream. Doing this allow sus to dive into the 'children'
      // property of the routes config to fetch the corresponding page title(s).
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      // Filter un-named (primary) router outlets only.
      .filter(route => route.outlet === 'primary')
      // Get the value of all the BehaviorSubject observables in the route so that
      // the values can be directly accessed from the route like 'route.data.foo'
      // instead of having to observe to the observable at the 'iteratee' callback
      // level and receive the data that way.
      .map(route => {
        const keys = Object.keys(route);
        const len = keys.length;

        for (let i = 0; i < len; i++) {
          if (route[keys[i]]) {
            if (route[keys[i]].getValue) {
              route[keys[i]] = route[keys[i]].getValue();
            }
          }
        }

        return route;
      })
      .subscribe(route => iteratee(route));
  }

  private populateEventMap() {
    this.events[RouteInterceptorEvents.NAVIGATION_START] = this.router.events.pipe(filter(e => e instanceof NavigationStart));
    this.events[RouteInterceptorEvents.NAVIGATION_END] = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    this.events[RouteInterceptorEvents.NAVIGATION_CANCEL] = this.router.events.pipe(filter(e => e instanceof NavigationCancel));
    this.events[RouteInterceptorEvents.NAVIGATION_ERROR] = this.router.events.pipe(filter(e => e instanceof NavigationError));
    this.events[RouteInterceptorEvents.CONFIG_LOAD_START] = this.router.events.pipe(filter(e => e instanceof RouteConfigLoadStart));
    this.events[RouteInterceptorEvents.CONFIG_LOAD_END] = this.router.events.pipe(filter(e => e instanceof RouteConfigLoadEnd));
    this.events[RouteInterceptorEvents.ROUTE_RECOGNIZED] = this.router.events.pipe(filter(e => e instanceof RoutesRecognized));
  }
}
