import {Injectable} from '@angular/core';
import {ServiceSupportService} from '@services/service-support.service';
import {ApplicationSessionService, SessionEvent} from '@services/application-session.service';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class MabbleEventService extends ServiceSupportService {

  constructor(protected ass: ApplicationSessionService,
              protected logger: NGXLogger,
              protected http: HttpClient) {
    super(ass, http);
  }


  public dispatch(sessionEvent: SessionEvent): void {
    this.logger.debug('🍎 mabble-event.service.dispatch', sessionEvent);


  }
}
