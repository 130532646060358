import {ApiResponse} from '@core/models/api-response.model';
import {ApplicationSessionService} from './application-session.service';
import {AuthService} from './auth.service';
import {
  deserialiseMabbleData,
  eom,
  MBL_CTX_SENDMSG,
  MblSendMsgPayload,
  ObjectEntity
} from '@core/models/application-session.model';
import {environment} from '@environments/environment';
import {from as fromPromise, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {RawFuelGraphAggregatedData} from '@fuel/models/fuel-capture.model';
import {ServiceSupportService} from './service-support.service';
import {ToastrService} from 'ngx-toastr';
import {validateNumber} from '@core/mabble.utils-const';

export class FileUploadForm {
  private _files: Array<any>;

  constructor(files?: Array<any>) {
    this._files = files || [];
  }

  addFile(file: any) {
    this._files = this._files || [];
    this._files.push(file);
  }

  get files(): any[] {
    return this._files;
  }
}

export class ApiPage<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  live: boolean;
  size: number;
  number: number;
  sort: any;
  first: boolean;
  numberOfElements: number;
  qbe?: ObjectEntity<T>;

  constructor(options?: any) {
    if (options && Object.keys(options).length > 0) {
      this.content = options.content;
      this.totalElements = options.totalElements;
      this.totalPages = options.totalPages;
      this.last = options.last;
      this.live = options.live || false;
      this.size = options.size;
      this.sort = options.sort;
      this.first = options.first;
      this.numberOfElements = options.numberOfElements;
      this.qbe = options.qbe;
    }
  }
}


/**
 * The object-entity service.
 */
@Injectable()
export class ObjectEntityService extends ServiceSupportService {

  /**
   * Instance configured application session
   */
  private uploaderURL: string;
  private sendmsgURL: string;
  protected url_local: string;
  protected url_aggregations: string;

  constructor(protected logger: NGXLogger,
              protected sessionService: ApplicationSessionService,
              protected authService: AuthService,
              protected http: HttpClient,
              public toastrService: ToastrService) {
    super(sessionService, http);
    // .../api/v1/oe-store/sendmsg/out
    this.sendmsgURL = this.url_ + '/oe-store/sendmsg/out';
    // .../api/v1/file/upload
    this.uploaderURL = this.url_ + this.application.api.endpoints.content.file;
    // .../api/v1/oe-store/**
    this.url_local = this.url_ + this.application.api.endpoints.capture.root;
    this.url_aggregations = this.url_ + '/fuel/aggregates';
  }


  create(oe: ObjectEntity<any>): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._post<ApiResponse>(url, headers, eom(this.auditObjectEntity(oe))).toPromise().then(
      fulfilled => fulfilled,
      rejected => new ApiResponse({source: rejected}));
  }

  update(oe: ObjectEntity<any>): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + oe.id;

    return super._put<ApiResponse>(url, headers, eom(oe)).toPromise().then(
      fulfilled => {
        this.logger.info('oe.service.update: fulfilled request ->', fulfilled);
        return fulfilled;
      },
      response => {
        this.logger.error('oe.service.update: failed to complete request ->', response);
        if (response && response.status === 409) {
          this.toastrService.error(
            'Conflict! Item has been updated by another user, unable to save. Please try again',
            'Error! - Save Failed',
            {
              timeOut: 0,
              closeButton: true
            }
          );
        }
        return Promise.reject(response);
      }
    );
  }

  save(oe: ObjectEntity<any>): Promise<ApiResponse> {
    if (oe && oe.id && oe.id !== 0 && oe._mblVersion) {
      return this.update(oe);
    } else {
      return this.create(oe);
    }
  }

  current(oeTypeKey: string, template: any): Promise<ObjectEntity<any>> {
    const oe = new ObjectEntity<any>({type: oeTypeKey, context: oeTypeKey, value: template});
    return this.getByType(oeTypeKey).toPromise().then(
      res => {
        if (res && res.length === 1) {
          return deserialiseMabbleData(new ObjectEntity(res[0]));
        } else {
          return this.create(eom(oe)).then(() => {
              return this.getByType(oeTypeKey).toPromise().then(created => {
                return deserialiseMabbleData(new ObjectEntity(created[0]));
              });
            }, err2 => {
              return err2;
            }
          );
        }
      },
      err => {
        return err;
      }
    ) as Promise<ObjectEntity<any>>;
  }

  //
  getAll(): Promise<ObjectEntity<any>[]> {
    this.logger.trace('object-entity.service.ts#getAll:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise()
      .then(oel => {
        const oer: any[] = [];
        if (oel && oel.length > 0) {
          oel.map(i => oer.push(deserialiseMabbleData(i)));
        }
        return oer;
      });
  }

  getFuelAggregations(province: string, district: string, year?: number): Promise<RawFuelGraphAggregatedData[]> {
    this.logger.trace('object-entity.service.ts#getFuelAggregations:');
    const headers = this.authService.getAuthenticatedHeaders();
    let url = this.url_aggregations + '?province=' + province;
    url += district ? '&district=' + district : '';
    url += (year != null && validateNumber(year)) ? '&year=' + year : '';
    return super._get<RawFuelGraphAggregatedData[]>(url, headers).toPromise();
  }


  //
  getOne<T>(id: number): Promise<T> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + id;
    return super._get<T>(url, headers).toPromise().then((oe) => {
      this.logger.info('oe.service.getOne: fulfilled request ->', oe);
      return deserialiseMabbleData(oe);
    });
  }


  //
  //
  retOEL(oel: any[]): any[] {
    const oer: any[] = [];
    if (oel && oel.length > 0) {
      oel.map(i => oer.push(deserialiseMabbleData(i)));
    }
    return oer;
  }

  getByType(type: string): Observable<any[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return fromPromise(super._get<any[]>(url, headers).toPromise()
      .then(oel => {
        return this.retOEL(oel);
      }));
  }

  getTyped<T>(type: string): Observable<T[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return fromPromise(super._get<ObjectEntity<T>[]>(url, headers).toPromise()
      .then(oel => {
        return this.retOEL(oel);
      }));
  }

  listByContextAndId(context: string, identifier: string): Promise<ObjectEntity<any>[]> {
    this.logger.trace('object-entity.service.ts#listByContextAndId:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/by-context?context=' + context + '&identifier=' + identifier;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise().then(this.retOEL);
  }

  listByTypeContextAndId<T>(type: string, context: string, identifier: string): Promise<T[]> {
    this.logger.trace('object-entity.service.ts#listByTypeContextAndId:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/by-type-context-id?type=' + type + '&context=' + context + '&identifier=' + identifier;
    return super._get<T[]>(url, headers).toPromise().then(this.retOEL);
  }


  pbeGet<T>(page: ApiPage<T>): Promise<ApiPage<T>> {
    return this.pbe<T>(page.qbe, page.number, page.size);
  }

  pbeNext<T>(page: ApiPage<T>): Promise<ApiPage<T>> {
    return this.pbe<T>(page.qbe, page.number + 1, page.size);
  }

  pbePrev<T>(page: ApiPage<T>): Promise<ApiPage<T>> {
    return this.pbe<T>(page.qbe, page.number - 1, page.size);
  }

  pbe<T>(example: ObjectEntity<any>, page?: number, size?: number): Promise<ApiPage<T>> {
    this.logger.trace('object-entity.service.ts#qbe:');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/qbe/' + (page || '0') + '/' + (size || '15');
    if (example.value && !example.data) {
      example.data = JSON.stringify(example.value);
    }
    return super._post<ApiPage<T>>(url, headers, example).toPromise()
      .then((value: ApiPage<T>) => {
        value.qbe = example;
        if (value && value.content && value.content.length > 0) {
          value.content.map(i => deserialiseMabbleData(i));
        }
        return value;
      });
  }

  promiseByType(type: string): Promise<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/type?type=' + type;
    return super._get<ObjectEntity<any>[]>(url, headers).toPromise().then(oel => {
      if (oel && oel.length > 0) {
        oel.map(i => deserialiseMabbleData(i));
      }
      return oel;
    });

  }


  observable_fromQBE(example: ObjectEntity<any>, page?: number, size?: number): Observable<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/qbe/' + (page || '0') + '/' + (size || '1500');
    if (example.value && !example.data) {
      example.data = JSON.stringify(example.value);
    }
    this.logger.trace('object-entity.service: searching for QBE ', example);
    return super
      ._post<ApiPage<ObjectEntity<any>>>(url, headers, example).pipe(
        map(value => {
          if (value && value.content && value.content.length > 0) {
            value.content.map(i => deserialiseMabbleData(i));
          }
          return value.content;
        }));
  }

  qbe(example: ObjectEntity<any>, page?: number, size?: number): Promise<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/qbe/' + (page || '0') + '/' + (size || '100');
    if (example.value && !example.data) {
      example.data = JSON.stringify(example.value);
    }
    this.logger.trace('object-entity.service: searching for QBE ', example);
    return super._post<ApiPage<ObjectEntity<any>>>(url, headers, example).toPromise()
      .then(value => {
        if (value && value.content && value.content.length > 0) {
          value.content.map(i => deserialiseMabbleData(i));
        }
        return value.content;
      });
  }

  qjp(parameters: { type: string, context: string, identifier: string, filters: Array<String> }): Promise<ObjectEntity<any>[]> {
    const {type, context, identifier, filters} = parameters;
    this.logger.trace('object-entity.service.ts#qjp:');
    this.logger.trace('object-entity.service.ts#qjp: /qjp/{type}/{context}/{identifier}');
    const headers = this.authService.getAuthenticatedHeaders();
    const url = [this.url_local, 'qjp', type, context, identifier].join('/');
    return super._post<ObjectEntity<any>[]>(url, headers, filters).toPromise()
      .then(oel => {
        if (oel && oel.length > 0) {
          oel.map(i => deserialiseMabbleData(i));
        }
        return oel;
      });
  }


  remove(id: number): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/' + id;
    return super._remove(url, headers).toPromise();
  }

  search(term: string): Observable<ObjectEntity<any>[]> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.url_local + '/search?name=' + encodeURI(term);
    return super._get<ObjectEntity<any>[]>(url, headers).pipe(map(r => r.map(oe => deserialiseMabbleData(oe))));
  }


  uploadFilesA(event): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const url = this.uploaderURL;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      super._post(url, headers, formData).toPromise();
    }
    return Promise.reject('No file payload input provided');
  }

  uploadFilesB(event): Promise<ApiResponse> {
    const formData: FileUploadForm = new FileUploadForm();
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      for (let x = 0; x < fileList.length; x++) {
        formData.addFile(fileList.item(x));
      }
    }

    const headers = this.authService.getAuthenticatedHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    const url = this.uploaderURL;
    return super._post<ApiResponse>(url, headers, formData).toPromise();
  }

  uploadFilesC(event): Promise<ApiResponse> {
    this.logger.trace('object-entity.service.ts:.uploadFiles: - [event]', event);
    const formData: FileUploadForm = new FileUploadForm();
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList.item(0);
      const fd: FormData = new FormData();
      fd.append('file', file, file.name);
      formData.addFile(fd);

      this.logger.trace('object-entity.service.ts:.uploadFiles: - [formData]', formData);
      const headers = this.authService.getAuthenticatedHeaders();
      headers.set('Content-Type', 'multipart/form-data');
      const url = this.uploaderURL;
      return super._post<ApiResponse>(url, headers, formData).toPromise();
    }
    return Promise.reject('No file payload input provided');
  }

  mailUser(recipient, msgBody, title, type, asHtml = false) {
    this.sendmsgURL = this.url_ + '/oe-store/sendmsg/out?asHtml=' + asHtml;
    const payload = new MblSendMsgPayload({
      type: type,
      context: MBL_CTX_SENDMSG,
      identifier: this.user.data.username,
      recipients: recipient,
      messageBody: msgBody,
      subject: (title === 'PEPFAR Community Grant Notification') ? title : this.application.defaults.title + ' ' + title
    });
    return this.sendmsg(payload);
  }

  sendmsg(payload: any): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.sendmsgURL;
    payload.context = MBL_CTX_SENDMSG;

    if (environment.production) {
      try {
        return super._post<ApiResponse>(url, headers, eom(payload)).toPromise().then(() => {
          return this.echoSendMsg(payload);
        });
      } catch (error) {
      }
    } else {
      // For testing purposes only
      // payload.messageBody = 'Testing...\n' + payload.recipients[0] + '\n\n' + payload.messageBody;
      // payload.recipients = ['luqmaan@8327.co.za'];
      return this.echoSendMsg(payload);
    }
  }

  echoSendMsg(payload: any): Promise<ApiResponse> {
    const headers = this.authService.getAuthenticatedHeaders();
    const url = this.sendmsgURL;

    payload.messageBody = 'Recipients: ' + payload.recipients[0] + '\n\n' + payload.messageBody;
    payload.recipients = ['martin+mblbot@8327.co.za'];
    payload.subject = payload.subject + ' ssitk = ' + this.application.ssitk;
    try {
      return super._post<ApiResponse>(url, headers, eom(payload)).toPromise();
    } catch (error) {
      this.logger.trace('object-entity.service.ts:.echoSendMsg: - FAILED', error);
    }
  }
}
