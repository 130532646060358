import {Injectable} from '@angular/core';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {RoleService} from '../role-service';
import {Role} from '@core/models/role.model';

@Injectable()
export class RolesResolver implements Resolve<Role[]> {
  constructor(private s: RoleService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Role[]> {
    return this.s.getAll().then(entities => {
      return entities || [];
    });
  }
}
